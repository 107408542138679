import httpClient  from '.'; 
const API_CONTROLLER = 'debt-customer/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                type: search.type,
                companyId: search.companyId,
                code: search.code,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    getForPayments(search) { 
        let url = API_CONTROLLER + 'get-for-payments';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                type: search.type,
                companyId: search.companyId
            }
        });
    },

    getRefunds(search) { 
        let url = API_CONTROLLER + 'get-refunds';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                companyId: search.companyId,
                orderId: search.orderId,
                objFee: search.objFee
            }
        });
    },

    getAllRefunds(companyId, orderId) { 
        let url = API_CONTROLLER + 'get-all-refunds';
        return httpClient.get(url, {
            params: {
                companyId: companyId,
                orderId: orderId
            }
        });
    },

    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code?staffId=' + staffId;
        return httpClient.get(url);
    },
}