import httpClient  from '../api'; 
const API_CONTROLLER = 'revenue/'; 

export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },
    
    getAll(orderId) { 
        let url = API_CONTROLLER + 'get-all';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },


    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                customerId: search.customerId,
                orderId: search.orderId,
                noBooking: search.noBooking,
                orderCode: search.orderCode,
                code: search.code,
                fromDateOrder: search.fromDateOrder,
                toDateOrder: search.toDateOrder,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    getRevenues(search) { 
        let url = API_CONTROLLER + 'get-revenues';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                code: search.code
            }
        });
    },

    getFeeOnbehalf(orderId) { 
        let url = API_CONTROLLER + 'get-fee-onbehalf';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getFeeTransport(orderId) { 
        let url = API_CONTROLLER + 'get-fee-transport';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getForDebt(customerId) { 
        let url = API_CONTROLLER + 'get-for-debt';
        return httpClient.get(url, {
            params: {
                customerId: customerId
            }
        });
    },

    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code?staffId=' + staffId;
        return httpClient.get(url);
    },
}